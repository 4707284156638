// 常量配置
import Vue from "vue";
import API from "@/api/api";
const Vthis = new Vue();

// frontend.originoo.com
// wlsz.risvion.com => www.wlsz.net.cn
// wlszresource.risvion.com => resource.wlsz.net.cn
// wlszstatic.risvion.com => static.wlsz.net.cn

const tencentUploadDomain = "https://resource.wlsz.net.cn/"; //存储桶的自定义加速域名
const StaticDomain = "https://szsmrg.oss-cn-shanghai.aliyuncs.com/"; //静态文件域名部分
const StaticFileDomain = StaticDomain + "WLSZ/WEB/"; //静态文件域名部分
const PluginsFileDomain = StaticDomain + "plugin/"; //插件文件域名部分
const webDomain = "https://www.wlsz.net.cn/"; //网站域名

export default {
  tencentUploadEnv: process.env.NODE_ENV,
  tencentUploadBucket: "netlink-resource-1313388492", //存储桶的名称
  tencentUploadRegion: "ap-shanghai", //存储桶所在地域
  tencentUploadDomain: tencentUploadDomain, //存储桶的自定义加速域名
  StaticFileDomain: StaticFileDomain, //静态文件域名部分
  PluginsFileDomain: PluginsFileDomain, //插件文件域名部分
  webDomain: webDomain, //网站域名
  appId: "wx56ad6d13441ed8d7", //appid
  shareIMG: StaticFileDomain + "wx/shareIcon.png", // 分享图标

  StaticFileDomainInPhone: StaticDomain + "/phone/",

  // PHONE手机下方tab栏
  PHONE_TAB_LIST: [
    {
      path: "/home",
      icon1: "tabicon/tabicon1.png",
      icon2: "tabicon/tabicon1_a.png",
      name: "首页",
      label: "首页",
    },
    {
      path: "/showPortraitModeByPhone",
      icon1: "tabicon/tabicon2.png",
      icon2: "tabicon/tabicon2_a.png",
      icon3: "tabicon/tabicon2-1.png",
      name: "名人检索",
      label: "名人检索",
    },
    {
      path: "/relics",
      icon1: "tabicon/tabicon3.png",
      icon2: "tabicon/tabicon3_a.png",
      name: "名人遗迹",
      label: "名人遗迹",
    },
    {
      path: "/readingRoomByPhone",
      icon1: "tabicon/tabicon4.png",
      icon2: "tabicon/tabicon4_a.png",
      icon3: "tabicon/tabicon4-1.png",
      name: "名人阅览室",
      label: "名人阅览室",
    },
    {
      path: "/exhibitionByPhone",
      icon1: "tabicon/tabicon5.png",
      icon2: "tabicon/tabicon5_a.png",
      name: "名人展厅",
      label: "名人展厅",
    },
  ],
};
