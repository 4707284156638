import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    headPortrait:'',
  },
  getters: {
  },
  mutations: {
    mutationsModifyHeadPortrait(state, value) {
      // console.log( state, value, 'mutationsIncrement');
      state.headPortrait = value;
    },
  },
  actions: {
    actionModifyHeadPortrait(context, value) {
      context.commit('mutationsModifyHeadPortrait', value)
    },
  },
  modules: {
  }
})
