var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[
    _vm.currPlatForm,
    _vm.$route.meta.routeType,
    _vm.$route.meta.isHasTabBar ? 'HASTABBAR' : '',
    _vm.$route.meta.isHasNavBar ? 'HASNAVBAR' : '',
    _vm.$route.meta.isHasNavBarSearch ? 'HASNAVBAR' : '',
    _vm.$route.meta.isHasNavBar && _vm.$route.meta.isHasNavBarSearch
      ? 'app-has-navbar-search'
      : '',
  ],attrs:{"id":"app"}},[_c('Header',{attrs:{"currPlatForm":_vm.currPlatForm}}),_c('NavBar',{attrs:{"currPlatForm":_vm.currPlatForm}}),_c('section',{staticClass:"mainWrap"},[_c('router-view',{attrs:{"currPlatForm":_vm.currPlatForm}})],1),(!_vm.$route.meta.hideFooter)?_c('Footer',{attrs:{"currPlatForm":_vm.currPlatForm}}):_vm._e(),_c('Tabbar',{attrs:{"currPlatForm":_vm.currPlatForm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }