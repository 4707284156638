import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

// import 'element-ui/lib/theme-chalk/index.css';
import "@/config/theme/theme/index.css";

import ElementUI from "element-ui";

Vue.use(ElementUI);

import Vant from "vant";
import "vant/lib/index.css";
// 引入全部样式
import 'vant/lib/index.less';

// 引入单个组件样式
import 'vant/lib/pagination/style/less';
Vue.use(Vant);
import { Picker } from "vant";

Vue.use(Picker);
import { Popup, ImagePreview } from "vant";

Vue.use(Popup);
Vue.use(ImagePreview);
//编码不渲染标签
import Fragment from "vue-fragment";

// console.log(Fragment,'Fragment')
Vue.use(Fragment.Plugin);
// 配置文件
import config from "@/config/js/config.js";
Vue.prototype.$config = config;

//uuid
import { nanoid } from "nanoid";
Vue.prototype.uuid = nanoid;

import moment from "moment";
Vue.prototype.$moment = moment;

const isDebug_mode = process.env.NODE_ENV !== "production";
// console.log(isDebug_mode,'isDebug_modeisDebug_mode')
Vue.config.debug = isDebug_mode;
Vue.config.devtools = isDebug_mode;
Vue.config.productionTip = isDebug_mode;

// Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
