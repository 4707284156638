<template>
  <fragment v-if="currPlatForm === 'PHONE' && $route.meta.isHasTabBar">
    <section class="tabBarWrap">
      <van-tabbar
        z-index="1000"
        :border="false"
        inactive-color="#BDC1CC"
        active-color="#455375"
        :fixed="true"
        :safe-area-inset-bottom="true"
        :placeholder="false"
        route
      >
        <fragment v-for="(item, index) in $config.PHONE_TAB_LIST" :key="index">
          <van-tabbar-item replace :to="item.path">
            <template #icon="props">
              <img
                :src="
                  props.active
                    ? $config.StaticFileDomainInPhone + item.icon2
                    : $config.StaticFileDomainInPhone + item.icon1
                "
              />
              <div v-if="!props.active && item.icon3" class="showIcon3">
                <img
                  :src="$config.StaticFileDomainInPhone + item.icon3"
                  alt=""
                />
              </div>
            </template>
            <span>{{ item.name }}</span>
          </van-tabbar-item>
        </fragment>
      </van-tabbar>
    </section>
  </fragment>
</template>

<script>
export default {
  name: "Tabbar",
  props: {
    // eslint-disable-next-line vue/require-default-prop
    currPlatForm: String,
  },
  mounted() {
    console.log(this.currPlatForm, "ccc", this.$route.meta.isHasTabBar);
  },
};
</script>

<style lang="less" scoped>
.tabBarWrap {
  background: #f56c6c;
  /*padding*/
}
/*van-tabbar van-tabbar--fixed*/
.van-tabbar {
  width: 100%;
  height: 104rem;
  /*background-color: #F56C6C;*/
}
.van-tabbar-item {
  padding-top: 6rem;
  box-sizing: border-box;
  background-color: transparent;
}
.van-tabbar-item--active {
  background-color: transparent;
}
.van-tabbar-item__icon {
  width: 32rem;
  height: 32rem;
}
.van-tabbar-item__icon img {
  width: 32rem;
  height: 32rem;
}
.van-tabbar-item__text {
  line-height: 24rem;
  font-size: 20rem;
  font-weight: 600;
}
.van-tabbar-item__text span {
}

.van-tabbar--fixed {
}

.showIcon3 {
  width: 60rem;
  height: 20rem;
  position: absolute;
  bottom: 16rem;
  left: 22rem;
  img {
    width: 60rem !important;
    height: 20rem !important;
  }
}
/*width: 750px;
height: 104px;
background: #FFFFFF;
box-shadow: 0px -1px 1px 0px #F5F6F8;
border-radius: 0px 0px 0px 0px;
opacity: 1;*/
</style>
