// 腾讯云上传
import Vue from 'vue'
import API from '@/api/api'

const Vthis = new Vue()

export default {
    hadleFileName: (filename) => {
        //处理文件名得到后缀 如 .jpg .png等
        let filename_ = filename.split('.');
        let filenameEnd = filename_[filename_.length - 1];
        filenameEnd = filenameEnd.toLowerCase()
        let endStr = '.' + filenameEnd;

        return endStr
    },
    handleUploadKey:(keyInfo)=>{
        //处理腾讯云上传的key
        let {path,isUseFilename,filename} = keyInfo;
        let dateTime = new Date();
        let Y = dateTime.getFullYear(),
            M = dateTime.getMonth() + 1,
            D = dateTime.getDate(),
            H = dateTime.getHours(),
            Mi = dateTime.getMinutes(),
            S = dateTime.getSeconds(),
            Ms = dateTime.getMilliseconds();
        Y = Y.toString();
        M = M < 10 ? ('0' + M) : M;
        D = D < 10 ? ('0' + D) : D;
        H = H < 10 ? ('0' + H) : H;
        Mi = Mi < 10 ? ('0' + Mi) : Mi;
        S = S < 10 ? ('0' + S) : S;
        Ms = Ms<10?('00' + Ms) : (Ms<100?('00' + Ms):Ms);

        let timeKey,UUID;
        timeKey = Y + M + D + '_' + H+Mi+S+Ms;
        UUID = '_'+Vthis.uuid(8).replace(/\-/g, '');

        let noFilenameKey = timeKey+UUID+'_'+Vthis.$config.hadleFileName(filename)

        let key =  path+'/'+(isUseFilename?filename:noFilenameKey)

        return key


    },


    handleFileUpload: (uploadParams) => {
        let {path, $this, filesList, isUseFilename, dialogRef, API_PATH} = uploadParams;
        let files = [];
        for (let file of filesList) {
            //处理成适合上传的files
            // console.log(file,'dddddddddddddd')
            let obj = {};
            obj.Bucket = $this.$config.tencentUploadBucket;
            obj.Region = $this.$config.tencentUploadRegion;
            obj.Key = $this.$config.handleUploadKey({
                path,
                isUseFilename,
                filename: file.name
            });
            // console.log(obj.Key, 'obj.Key')
            obj.Body = file;
            /*********处理设计上传的压缩包***********/
            if(file.uploadType === 'design'){
                let arr = (obj.Key).split('.');
                // console.log(arr,'arrarr')
                let zipimgKey = arr[0];
                console.log(zipimgKey)
                let zipFile = {
                    Bucket : $this.$config.tencentUploadBucket,
                    Region : $this.$config.tencentUploadRegion,
                    Key:zipimgKey+$this.$config.hadleFileName(file.ZIPIMGINFO.name),
                    Body:file.ZIPIMGINFO.file,
                }
                obj.zipFile = zipFile;
            }
            /*********处理设计上传的压缩包***********/

            /*********处理图片上传的小图***********/
            if(file.uploadType === 'picture'){
                // console.log(file,'44444')
                let C_Key = (obj.Key).replace('/picture/','/picturesmall/');
                let CFILE = {
                    Bucket : $this.$config.tencentUploadBucket,
                    Region : $this.$config.tencentUploadRegion,
                    Key:C_Key,
                    Body:file.C_file,
                }
                obj.CFILE = CFILE;
            }
            /*********处理图片上传的小图***********/
            files.push(obj);
        }
        console.log(files,'files  -- 上传')

        let uploadFiles = []
        for(let item of files){
            console.log(item,'   return')
            let obj = {
                Bucket : item.Bucket,
                Region : item.Region,
                Key:item.Key,
                Body:item.Body,
            }
            uploadFiles.push(obj)

            if(item.Body.uploadType === 'design'){
                uploadFiles.push(item.zipFile)
            }
            if(item.Body.uploadType === 'picture'){
                uploadFiles.push(item.CFILE)
            }

        }
        console.log(uploadFiles,'uploadFiles   -- 上传')

        // return
        $this.$refs[dialogRef].visible = true;
        $this.$refs[dialogRef].uploadInfo = {};
        return new Promise((resolve, reject) => {
            let cos = new COS({
                getAuthorization: function (options, callback) {
                    let params = {};
                    params.path = API_PATH;
                    API.tencentUploadToken(params).then(res => {
                        let data = res.data;
                        // console.log(res, 'resres');
                        callback({
                            TmpSecretId: data.credentials.tmpSecretId,
                            TmpSecretKey: data.credentials.tmpSecretKey,
                            XCosSecurityToken: data.credentials.sessionToken,
                            ExpiredTime: data.expiredTime
                        })
                    }).catch(err => {
                        console.log(err, 'cw');
                        $this.$message.error('上传失败')
                        $this.$refs[dialogRef].visible = false;
                        reject(err)
                    })
                }
            })
            cos.uploadFiles({
                    files: uploadFiles,
                    SliceSize: 1024 * 1024,
                    onProgress: function (info) {
                        // console.log(info, "上传进度");
                        $this.$refs[dialogRef].uploadInfo = info;
                    },
                    onFileFinish: function (err, data, options) {
                        // console.log("onFileFinish", options.Key + "上传" + (err ? "失败" : "完成"));
                    },
                },
                function (err, data) {
                    console.log(err || data);
                    console.log(err, data)
                    let list = data.files;
                    $this.$refs[dialogRef].visible = false;
                    for (let item of list) {
                        if (item.error) {
                            $this.$message.error('上传失败')
                            reject(err)
                            return
                        }
                    }
                    // $this.$message.success('上传成功')
                    resolve({files,data})
                }
            );
        })


        /*sendAjax('get',`tencent/uploadedToken/${path}`,params).then(res => {
    let data = res.data;
    console.log(res,'ddd')
    callback({
      TmpSecretId: data.credentials.tmpSecretId,
      TmpSecretKey: data.credentials.tmpSecretKey,
      XCosSecurityToken: data.credentials.sessionToken,
      ExpiredTime: data.expiredTime
    });
    }).catch(error => {
    $this.$message.error('上传失败')
    $this.$refs[ref].visible = true;
    })*/

    },


}

