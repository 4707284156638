import Vue from "vue";
import VueRouter from "vue-router";
let Vthis = new Vue();
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const originalreplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location) {
  return originalreplace.call(this, location).catch((err) => err);
};

import $config from "@/config/js/config";
// import {has} from "core-js/internals/internal-state";
const templateName = (path) => () => import(`@/pages/${path}`);

const NotFoundComponent = templateName("err.vue"); // 404

/************pc****************/
const Home = templateName("home/index.vue"); // 首页
const Home2 = templateName("home2/index.vue"); // 首页
const celebrity = templateName("celebrity/index.vue"); // 三维滚动展示
const celebrityDetail = templateName("celebrityDetail/index.vue"); // 三维滚动展示
const loginH5 = templateName("userCenterForPhone/h5loginForSZD"); //h5授权登录

const root = Vue.component("root", {
  props: {
    currPlatForm: String,
  },
  template:
    // '<keep-alive><router-view :currPlatForm="currPlatForm"></router-view></keep-alive>',
    '<router-view :currPlatForm="currPlatForm"></router-view>',
});
const redirectRoute = [
  {
    path: "/",
    // redirect:'/repertoire',
    meta: {
      title: "名人",
      isLogin: false, //是否需要登录
      role: [],
    },
    redirect: {
      path: "/home",
    },
  },
];

const unLoginRoute = [
  {
    path: "*",
    name: "页面不存在",
    component: NotFoundComponent,
    meta: {
      title: "页面不存在",
      isLogin: false, //是否需要登录
      role: [],
      routeType: "ordinaryRoute",
      isHasTabBar: true,
    },
  },
];

const ordinaryRoute = [
  {
    path: "/home",
    name: "首页",
    component: Home,
    meta: {
      isLogin: false, //是否需要登录
      role: [],
      title: "首页",
      label: "首页",
      routeType: "ordinaryRoute",
      isHasTabBar: true,
      isHasNavBarSearch: false,
    },
  },
];

const userCenterForPhone = [
  {
    path: "/loginH5",
    name: "苏周到授权",
    component: loginH5,
    meta: {
      isLogin: false, //是否需要登录
      role: [],
      title: "苏周到授权",
      breadcrumbTitile: "苏周到授权",
      label: "",
      routeType: "szipOrdinaryrRoute",
      isHasTabBar: false,
    },
  },
];
const breadcrumbRoute = [
  {
    path: "/home",
    name: "首页",
    component: root,
    meta: {
      isLogin: false, //是否需要登录
      role: [],
      title: "首页",
      label: "首页",
      breadcrumbTitile: "首页",
      routeType: "ordinaryRoute",
      isHasTabBar: false,
    },
    children: [
      {
        path: "/showPortraitMode",
        name: "人物列表",
        component: templateName("showPersonMode/index.vue"),
        meta: {
          isLogin: false, //是否需要登录
          role: [],
          title: "人物列表",
          label: "人物列表",
          breadcrumbTitile: "人物列表",
          routeType: "ordinaryRoute",
          redirect: {
            path: "/showPortraitModeByPhone",
          },
        },
      },
      {
        path: "/renwuliebiao",
        name: "人物列表",
        component: root,
        meta: {
          isLogin: false, //是否需要登录
          role: [],
          title: "人物列表",
          label: "人物列表",
          breadcrumbTitile: "人物列表",
          routeType: "ordinaryRoute",
        },
        children: [
          {
            path: "/searchDetail",
            name: "人物详情",
            component: templateName("search/detail/index.vue"),
            meta: {
              isLogin: false, //是否需要登录
              role: [],
              title: "人物详情",
              label: "人物详情",
              breadcrumbTitile: "人物详情",
              routeType: "ordinaryRoute",
              isHasNavBar: true,
              isHasNavBarSearch: true,
            },
          },
        ],
      },
      {
        path: "/mingrenjiansuo",
        name: "检索",
        component: root,
        meta: {
          isLogin: false, //是否需要登录
          role: [],
          title: "检索",
          label: "检索",
          breadcrumbTitile: "检索",
          routeType: "ordinaryRoute",
        },
        // celebrityDetail
        //
        children: [
          {
            path: "/celebrity",
            name: "三维滚动展示",
            component: celebrity,
            meta: {
              isLogin: false, //是否需要登录
              role: [],
              title: "三维滚动展示",
              label: "三维滚动展示",
              breadcrumbTitile: "三维滚动展示",
              routeType: "ordinaryRoute",
            },
            children: [
              {
                path: "/celebrityDetail",
                name: "三维滚动展示详情",
                component: celebrityDetail,
                meta: {
                  isLogin: false, //是否需要登录
                  role: [],
                  title: "三维滚动展示详情",
                  label: "三维滚动展示详情",
                  breadcrumbTitile: "三维滚动展示详情",
                  routeType: "ordinaryRoute",
                },
              },
            ],
          },

          {
            path: "/search",
            name: "搜索",
            component: templateName("search/index.vue"),
            meta: {
              isLogin: false, //是否需要登录
              role: [],
              title: "搜索",
              label: "搜索",
              breadcrumbTitile: "搜索",
              routeType: "ordinaryRoute",
              isHasNavBar: true,
              isHasNavBarSearch: true,
              isHasTabBar: true,
            },
          },
        ],
      },
      {
        path: "/readingRoom",
        name: "ReadRoom",
        component: templateName("readRoom/index.vue"),
        meta: {
          isLogin: false, //是否需要登录
          role: [],
          title: "阅览室",
          label: "阅览室",
          breadcrumbTitile: "阅览室",
          routeType: "ordinaryRoute",
          redirect: {
            path: "/readingRoomByPhone",
          },
        },
      },
      {
        path: "/exhibition",
        name: "名人展厅",
        component: templateName("exhibition/index.vue"),
        meta: {
          isLogin: false, //是否需要登录
          role: [],
          title: "名人展厅",
          label: "名人展厅",
          breadcrumbTitile: "名人展厅",
          routeType: "ordinaryRoute",
          isHasNavBarSearch: true,
          isHasTabBar: true,
          redirect: {
            path: "/exhibitionByPhone",
          },
        },
      },
      {
        path: "/resource",
        name: "阅览室",
        component: root,
        meta: {
          isLogin: false, //是否需要登录
          role: [],
          title: "阅览室",
          label: "阅览室",
          breadcrumbTitile: `阅览室`,
          routeType: "ordinaryRoute",
          isHasNavBarSearch: true,
        },
        children: [
          {
            path: "detail",
            name: "详情",
            component: templateName("resource/detail.vue"),
            meta: {
              isLogin: false, //是否需要登录
              role: [],
              title: "详情",
              label: "详情",
              breadcrumbTitile: `详情`,
              routeType: "ordinaryRoute",
              isHasNavBarSearch: true,
            },
          },
        ],
      },
      {
        path: "/wall",
        name: "wall",
        component: root,
        meta: {
          isLogin: false, //是否需要登录
          role: [],
          title: "名人风采",
          label: "名人风采",
          breadcrumbTitile: "名人风采",
          routeType: "ordinaryRoute",
        },
        children: [
          {
            path: "/wall",
            name: "详情",
            component: templateName("3dwall/index.vue"),
            meta: {
              isLogin: false, //是否需要登录
              role: [],
              title: "wall",
              label: "wall",
              breadcrumbTitile: `wall`,
              routeType: "ordinaryRoute",
              isHasNavBarSearch: true,
            },
          },
          {
            path: "/wallDetail",
            name: "详情",
            component: templateName("3dwall/detail/index.vue"),
            meta: {
              isLogin: false, //是否需要登录
              role: [],
              title: "详情",
              label: "详情",
              breadcrumbTitile: `详情`,
              routeType: "ordinaryRoute",
              isHasNavBarSearch: true,
              hideFooter: true,
            },
          },
        ],
      },
      {
        path: "/activity",
        name: "activity",
        component: templateName("activity/index.vue"),
        meta: {
          isLogin: false, //是否需要登录
          role: [],
          title: "最新动态",
          label: "最新动态",
          breadcrumbTitile: "最新动态",
          routeType: "ordinaryRoute",
          isHasNavBar: true,
        },
      },
      {
        path: "/activity",
        name: "activity",
        component: root,
        meta: {
          isLogin: false, //是否需要登录
          role: [],
          title: "最新动态",
          label: "最新动态",
          breadcrumbTitile: "最新动态",
          routeType: "ordinaryRoute",
        },
        children: [
          {
            path: "/activityDetail",
            name: "详情",
            component: templateName("activity/detail.vue"),
            meta: {
              isLogin: false, //是否需要登录
              role: [],
              title: "详情",
              label: "详情",
              breadcrumbTitile: `详情`,
              routeType: "ordinaryRoute",
              isHasNavBar: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/relics",
    name: "遗迹",
    component: templateName("relics/index.vue"),
    meta: {
      isLogin: false, //是否需要登录
      role: [],
      title: "遗迹",
      label: "遗迹",
      breadcrumbTitile: "遗迹",
      routeType: "ordinaryRoute",
      isHasTabBar: true,
    },
  },
  {
    path: "/showPortraitModeByPhone",
    name: "人像展示模式",
    component: templateName("showPersonMode/index.vue"),
    meta: {
      isLogin: false, //是否需要登录
      role: [],
      title: "人像展示模式",
      label: "人像展示模式",
      breadcrumbTitile: "人像展示模式",
      routeType: "ordinaryRoute",
      isHasTabBar: true,
      isHasNavBarSearch: true,
      isHasNavBar: false,
      redirect: {
        path: "/showPortraitMode",
      },
    },
  },
  {
    path: "/readingRoomByPhone",
    name: "阅览室",
    component: templateName("readRoom/index.vue"),
    meta: {
      isLogin: false, //是否需要登录
      role: [],
      title: "阅览室",
      label: "阅览室",
      breadcrumbTitile: "阅览室",
      routeType: "ordinaryRoute",
      isHasTabBar: true,
      isHasNavBarSearch: true,
      redirect: {
        path: "/readingRoom",
      },
    },
  },
  {
    path: "/exhibitionByPhone",
    name: "名人展厅",
    component: templateName("exhibition/index.vue"),
    meta: {
      isLogin: false, //是否需要登录
      role: [],
      title: "名人展厅",
      label: "名人展厅",
      breadcrumbTitile: "名人展厅",
      routeType: "ordinaryRoute",
      isHasNavBarSearch: true,
      isHasTabBar: true,
      redirect: {
        path: "/exhibition",
      },
    },
  },
  {
    path: "/reading",
    name: "在线阅读",
    component: templateName("readPdf/index.vue"),
    meta: {
      isLogin: false, //是否需要登录
      role: [],
      title: "在线阅读",
      label: "在线阅读",
      breadcrumbTitile: "在线阅读",
      routeType: "ordinaryRoute",
      isHasNavBar: true,
      isHasNavBarSearch: true,
    },
  },
  {
    path: "/exhibitionInPhone",
    name: "名人展厅",
    component: templateName("exhibition/phoneIndex.vue"),
    meta: {
      isLogin: false, //是否需要登录
      role: [],
      title: "名人展厅",
      label: "名人展厅",
      breadcrumbTitile: "名人展厅",
      routeType: "ordinaryRoute",
      isHasNavBar: true,
    },
  },
  {
    path: "/atlasList",
    name: "图集列表",
    component: templateName("search/detail/components/atlas/phoneAtlasList.vue"),
    meta: {
      isLogin: false, //是否需要登录
      role: [],
      title: "图集列表",
      label: "图集列表",
      breadcrumbTitile: "图集列表",
      routeType: "ordinaryRoute",
      isHasNavBar: true,
    },
  },
];

const loginRoute = [];
const asideRoute = [];
const routes = [
  ...userCenterForPhone,
  ...redirectRoute,
  ...unLoginRoute,
  ...loginRoute,
  ...asideRoute,
  ...ordinaryRoute,
  ...breadcrumbRoute,
];
const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.path === "/home" && from.path === "/home" && to.hash) {
      return {};
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // 路由发生变化 判断将要去的页面的权限
  let { path, hash, meta } = to;
  let { redirect } = meta;
  const currPlatForm = $config.obtainCurrPlatform(); //PC--/PHONE
  console.log(currPlatForm, "currPlatForm");
  if (currPlatForm === "PC") {
    if (meta.isLogin) {
      //需要登录
      if ($config.judgeIsLogin()) {
        // 判断权限
        if (meta.role.length) {
          let isT = $config.authorityJudge(to.meta.role);
          if (!isT) {
            Vthis.$message.error("您无权限来浏览此页面");
            next("/admin/personal");
          } else {
            next();
          }
        }
      } else {
        Vthis.$message.error("您需要登录后才能浏览此页面,请登录");
        next("/home");
      }
    } else {
      //不需要登录  非后台

      if (
        [
          "/showPortraitModeByPhone",
          "/readingRoomByPhone",
          "/exhibitionByPhone",
        ].includes(path)
      ) {
        next({
          path: redirect.path,
          hash: redirect.hash,
          replace: true,
        });
      } else {
        next();
      }
    }
  } else if (currPlatForm === "PHONE") {
    if (["/showPortraitMode", "/readingRoom", "/exhibition"].includes(path)) {
      next({
        path: redirect.path,
        hash: redirect.hash,
        replace: true,
      });
    } else {
      next();
    }
  } else {
    next();
  }

  /*
    if($config.getStorageInfo('token')){

    }*/
});

// router.beforeEach((to,from,next) =>{
//     // 路由发生变化 判断将要去的页面的权限
//     if($config.getStorageInfo('token')){
//         if (to.meta.role.length){
//             let isT = $config.authorityJudge(to.meta.role)
//             if (!isT){
//                 Vthis.$message.error('您无权限来浏览-'+to.meta.title)
//                 next('/article')
//             }else{
//                 next()
//             }
//         }else{
//             next()
//         }
//     }else{
//         next()
//     }
//
// })
export default router;
