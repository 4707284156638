<template>
  <fragment v-if="currPlatForm === 'PC'">
    <section
      id="app-header"
      :class="[
        'headerWrapPC',
        'headerItem_home',
        isNoBColorheader && scroll ? 'isNoBColor' : '',
      ]"
    >
      <!-- <div style="width: 100%;background-color: ;">
      
    </div> -->
      <div class="box_60 flex-row">
        <div class="text_55 logo" @click="logoClick" />

        <div class="headerWarp">
          <div
            v-for="item in title"
            :key="item.name"
            :class="['headerItem', { active_bg: item.active }]"
            @click="goto(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
// import API from "@/api/api.js";
import { mixins } from "@/mixin/index";
export default {
  name: "Header",
  mixins: [mixins],
  // eslint-disable-next-line vue/require-prop-types
  props: ["currPlatForm"],
  data() {
    return {
      scroll: true,
      title: [
        {
          name: "首页",
          path: "/home",
          active: true,
          activeLink: ["/home"],
        },
        {
          name: "名人风采",
          path: "/wallDetail?name=先秦至宋元",
          active: true,
          activeLink: ["/celebrity", "/search", "/showPortraitMode"],
        },
        {
          name: "遗迹地图",
          path: "/relics",
          active: true,
          activeLink: ["/relics"],
        },
        {
          name: "阅览室",
          path: "/readingRoom",
          active: false,
          activeLink: ["/readingRoom", "/resource/detail"],
        },
        {
          name: "名人展厅",
          path: "/exhibition",
          activeLink: ["/exhibition"],
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(now, old) {
        this.handleHeaderTitleActive(now);
      },
    },
  },
  created() {
    this.handleGetInto();
  },
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", (e) => {
      this.handleScroll();
    });
  },
  methods: {
    handleGetInto() {},
    handleHeaderTitleActive(route) {
      this.title.forEach((item) => {
        if (item.activeLink.includes(route.path)) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    handleScroll() {
      let scrollH =
        document.documentElement.scrollTop || document.body.scrollTop;
      const headerH = document.getElementById("app-header")?.offsetHeight || 0;
      if (scrollH >= headerH) {
        this.scroll = false;
      } else this.scroll = true;
    },
    goto(item) {
      this.$router.push(item.path);
    },
    logoClick() {
      window.location.href = "/";
    },
  },
};
</script>

<style lang="less" scoped>
.headerItem_home {
  width: 100%;
  height: 70px;
  position: sticky;
  top: 0px;
  z-index: 999;
  &.isNoBColor {
    position: sticky;
    .box_60 {
      background-color: rgba(0, 0, 0, 0);
      .headerWarp {
        color: #fff;
      }
      .logo {
        cursor: pointer;
        width: 276px;
        height: 32px;
        background: url("https://www.szsmrg.com/static202407091632/img/logo2.0cbf9469.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .box_60 {
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    margin: 0 auto;
    height: 70px;
    box-sizing: border-box;
    padding: 0 160px;
    &.flex-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .logo {
      cursor: pointer;
      width: 276px;
      height: 32px;
      background: url("https://www.szsmrg.com/static202407072219/img/logo.9c807709.png") no-repeat;
      background-size: 100% 100%;
    }
    .headerWarp {
      width: 550px;
      display: flex;
      justify-content: space-between;
      // margin: 0 auto;
      font-family: PingFang SC;
      font-weight: bold;
      color: #345251;
      font-size: 18px;
    }
    .headerItem {
      width: 100px;
      text-align: center;
      padding: 6px 10px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .active_bg {
      background: url("../assets/img/header_title_bg.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
