<template>
  <fragment v-if="currPlatForm === 'PHONE'">
    <section v-if="$route.meta.isHasNavBar" class="navBarBox">
      <van-nav-bar
        left-arrow
        :placeholder="false"
        :safe-area-inset-top="true"
        fixed
        :border="false"
        z-index="1000"
        @click-left="onClickLeft"
      >
        <div id="navTitle" slot="title">{{ $route.meta.title }}</div>
      </van-nav-bar>
    </section>
    <section
      v-if="$route.meta.isHasNavBarSearch"
      :class="[
        'navBarBox',
        'searchNavBar',
        $route.meta.isHasNavBar && $route.meta.isHasNavBarSearch
          ? 'hasAll'
          : '',
      ]"
    >
      <van-nav-bar
        :placeholder="false"
        :safe-area-inset-top="true"
        fixed
        :border="false"
        z-index="1000"
        @click-left="onClickLeft"
      >
        <div slot="title">
          <div class="searchIpt">
            <van-search
              v-model="keyword"
              placeholder="搜索感兴趣的信息哦~"
              @search="clickSearch"
            />
          </div>
        </div>
      </van-nav-bar>
    </section>
  </fragment>
</template>
<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    currPlatForm: String,
  },
  data() {
    return {
      keyword: "",
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(to, from) {
        let query = this.$route.query;
        // console.log(query, 'query')
        //变化时，
        // this.keyword = query.keyword ? query.keyword : "风景";
      },
    },
  },

  mounted() {},
  methods: {
    clickSearch() {
      if (!this.keyword) {
        this.$toast("请填写搜索关键词");
        return;
      }
      this.$router.push({
        path: "/search",
        query: {
          keywords: this.keyword,
        },
      });
    },
    onClickLeft() {
      let num = "";
      let that = this;

      let len = window.history.length || history.length;
      if (len > 1) {
        that.$router.go(-1);
      } else {
        that.$router.replace("/home");
      }

      // if (!num) {
      //   num = -1;
      // }
      /*  try {
        let len = window.history.length||history.length;
        if (len > 1) {
          that.$router.go(-1);
        } else {
          that.$router.replace("/wlwhjjh");
        }
      } catch (e) {
        console.log(220);
        that.$router.go(num);
      }*/
    },
  },
};
</script>
<style lang="less" scoped>
/*van-nav-bar van-nav-bar--fixed van-nav-bar--safe-area-inset-top*/

.van-nav-bar {
  background-color: #fff;
  height: 100rem;
}
.navBarBox /deep/ .van-nav-bar__content {
  height: 100% !important;
  justify-content: center;
}

#navTitle {
  color: #333;
  font-weight: bold;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.navBarBox /deep/ .van-icon-arrow-left {
  color: #434547;
}

/*********手机*************/
.searchNavBar :deep(.van-nav-bar__title) {
  width: 100%;
  max-width: 100%;
}
.searchNavBar :deep(.van-icon) {
  color: #979797;
}
.searchNavBar :deep(.van-search__content) {
  border-radius: 16px;
}
.hasAll :deep(.van-nav-bar--fixed) {
  top: ~"calc(100rem + var(--safe-area-top-constant))";
  top: ~"calc(100rem + var(--safe-area-top-env))";
}
</style>
