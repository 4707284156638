export const mixins = {
  computed: {
    isNoBColorheader() {
      return ["/readingRoom"].includes(this.$route.path);
    },
  },
  methods: {
    isOddOrEven(num) {
      if (num % 2 === 0) {
        // console.log("这是一个偶数");
        return 0;
      } else {
        return 1;
        // console.log("这是一个奇数");
      }
    },
  },
};
