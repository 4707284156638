// 方法配置
import Vue from "vue";
const Vthis = new Vue();

export default {
  obtainCurrPlatform: () => {
    // 判断当前使用的平台 PC PHONE
    let ios = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
    let android = /(Android)/i.test(navigator.userAgent);
    // console.log(ios,android)
    if (ios || android) {
      const deviceWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      // deviceWidth = 750px(设计图w)
      // document.querySelector('html').style.fontSize = deviceWidth / 100 / 7.5 + 'px';
      document.documentElement.style.fontSize = deviceWidth / 100 / 7.5 + "px";
      return "PHONE";
    } else {
      return "PC";
    }
  },
  // obtainCurrPlatform:()=>{
  //       // 判断当前使用的平台 PC PHONE
  // 			let ios = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
  // 			let android = /(Android)/i.test(navigator.userAgent);
  // 			// console.log(ios,android)
  // 			if(ios||android){
  // 					const deviceWidth = document.documentElement.clientWidth || document.body.clientWidth;
  // 					// deviceWidth = 750px(设计图w)
  // 					// document.querySelector('html').style.fontSize = deviceWidth / 100 / 7.5 + 'px';
  // 					document.documentElement.style.fontSize = deviceWidth / 100 / 7.5 + 'px';
  // 					return 'PHONE'
  // 			}else{
  // 					return 'PC'
  // 			}
  //   },
  handleOnresize: (resizeInfo) => {
    //处理窗口变化时，元素高度的变化
    let { W, H, ID, type, $this } = resizeInfo;
    if (type === "add") {
      let currPlatForm = $this.$config.obtainCurrPlatform();
      if (currPlatForm === "PC") {
        calculateResize();
        if (window.addEventListener) {
          //所有主流浏览器，除了 IE 8 及更早 IE版本
          window.addEventListener("resize", calculateResize);
        } else if (window.attachEvent) {
          // IE 8 及更早 IE 版本
          window.attachEvent("resize", calculateResize);
        }
      }
    } else if (type === "cancel") {
      // window.removeEventListener("resize",calculateResize);
      if (window.removeEventListener) {
        // // 所有浏览器，除了 IE 8 及更早IE版本
        window.removeEventListener("resize", calculateResize);
      } else if (window.detachEvent) {
        // IE 8 及更早IE版本
        window.detachEvent("resize", calculateResize);
      }
    }

    function calculateResize() {
      const timer = setInterval(() => {
        let element = document.getElementById(ID);
        if (element) {
          clearInterval(timer);
          let elementW = element.offsetWidth;
          let real_h = (elementW * H) / W;
          element.style.height = real_h + "px";
        }
      }, 10);
    }
  },
  handleFormatDate: (date) => {
    if (!date) {
      return {
        Y: "----",
        M: "--",
        D: "--",
        H: "--",
        Mi: "--",
        S: "--",
        Ms: "---",
      };
    }
    let nDate = date.replace(/\-/g, "/");
    let dateTime = new Date(nDate);
    let Y = dateTime.getFullYear(),
      M = dateTime.getMonth() + 1,
      D = dateTime.getDate(),
      H = dateTime.getHours(),
      Mi = dateTime.getMinutes(),
      S = dateTime.getSeconds(),
      Ms = dateTime.getMilliseconds();
    Y = Y.toString();
    M = M < 10 ? "0" + M : M;
    D = D < 10 ? "0" + D : D;
    H = H < 10 ? "0" + H : H;
    Mi = Mi < 10 ? "0" + Mi : Mi;
    S = S < 10 ? "0" + S : S;
    Ms = Ms < 10 ? "00" + Ms : Ms < 100 ? "0" + Ms : Ms;
    return { Y, M, D, H, Mi, S, Ms };
  },
  handleFormatTime(time) {
    console.log(time, "ffffffffffffff");
    time = time - 0;
    // return {H:'00',M:'00',S:'00'}
    if (!time) {
      return { H: "00", M: "00", S: "00" };
    }
    let H = Math.floor(time / 60 / 60);
    let M = Math.floor(time / 60);

    let S = time % 60;
    H = H < 10 ? "0" + H : H;
    M = M < 10 ? "0" + M : M;
    S = S < 10 ? "0" + S : S;
    return { H, M, S };
  },

  authorityJudge: (needRole) => {
    //权限判断
    // console.log(needRole, 'needRole');
    //token 信息
    let tokenInfo = Vthis.$config.analysisToken();
    // console.log(tokenInfo, 'tokenInfo');
    let userRole = tokenInfo.userRole ? tokenInfo.userRole : [];
    //判断用户是否存在需要的角色
    let isInclude = false;
    for (let item of needRole) {
      isInclude = userRole.includes(item);
      if (isInclude) break;
    }
    // console.log(isInclude, 'isIncludeisInclude');
    return isInclude;
  },
  carryOutDownloadFile: (fileInfo) => {
    //执行下载
    let { filename, fileurl, $this } = fileInfo;
    /**/
    /*          const a = document.createElement('a');
          let f = $this.$config.hadleFileName(fileurl);
          a.download = filename+f;
          a.href = fileurl;
          a.style.display = 'none'
          a.target = '_blank'
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);*/
    const loading = $this.$loading({
      lock: true,
      text: "下载处理中······",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    var xhr = new XMLHttpRequest();
    xhr.open("GET", fileurl, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (xhr.status === 200) {
        loading.close();
        let blob = xhr.response;
        var link = document.createElement("a");
        var body = document.querySelector("body");
        link.href = window.URL.createObjectURL(blob);
        let f = $this.$config.hadleFileName(fileurl);
        link.download = filename + f;
        link.style.display = "none";
        link.click();
      } else {
        loading.close();
        $this.$message.error("下载失败");
      }
    };
    xhr.send();
    /*let alink = document.createElement("a");
        alink.href = downloadUrl;
        alink.click()*/
  },
  handleListDelPage: (pagination, delNum) => {
    //处理分页列表 的删除问题
    let { c_total, c_size, c_page } = pagination;
    // console.log(c_total, 'c_total', c_size, 'c_size', c_page, 'c_page', delNum, 'delNum');
    if (!delNum) delNum = 1;
    // console.log(delNum, 'delNum2');
    c_total = c_total - delNum;
    // console.log(c_total, c_size);
    let page = Math.ceil(c_total / c_size);
    if (page == 0) {
      return 1;
    }
    if (c_page <= page) {
      return c_page;
    } else {
      return page--;
    }
  },
  // 手机端加载列表
  toastLoading: ({ type, duration, message, loadingType, toast }) => {
    if (type == "open") {
      let toastO = Vthis.$toast.loading({
        duration, // 持续展示 toast
        forbidClick: true,
        message,
        loadingType,
      });
      return toastO;
    } else if (toast) {
      toast.clear();
    }
  },

  formValidatePhone: (rule, value, callback) => {
    //验证表单手机号
    // console.log(rule, value);
    let sss = Vthis.$config.phoneReg.test(value);
    if (!sss) {
      callback(new Error("请输入正确格式的手机号"));
    } else {
      callback();
    }
  },
  formValidateVerifyCode: (rule, value, callback) => {
    //验证表单手机号
    // console.log(rule, value);
    let sss = Vthis.$config.regVerifyCode.test(value);
    if (!sss) {
      callback(new Error("请输入正确的验证码"));
    } else {
      callback();
    }
  },

  //后台内容处理上传

  handleUploadKey1_tu: (path, filename) => {
    console.log(path, filename, "处理腾讯云上传的key");
    let dateTime = new Date();
    let Y = dateTime.getFullYear(),
      M = dateTime.getMonth() + 1,
      D = dateTime.getDate(),
      H = dateTime.getHours(),
      Mi = dateTime.getMinutes(),
      S = dateTime.getSeconds(),
      Ms = dateTime.getMilliseconds();
    Y = Y.toString();
    M = M < 10 ? "0" + M : M;
    D = D < 10 ? "0" + D : D;
    H = H < 10 ? "0" + H : H;
    Mi = Mi < 10 ? "0" + Mi : Mi;
    S = S < 10 ? "0" + S : S;
    let timestamp = dateTime.getTime().toString();
    let UUID = Vthis.uuid(12).replace(/\-/g, "");
    let KEY =
      path +
      Y +
      M +
      D +
      "_" +
      H +
      Mi +
      S +
      Ms +
      "_" +
      UUID +
      Vthis.$config.hadleFileName(filename);
    return KEY;
  },
  handleUploadKey2_tu: (path, filename) => {
    console.log(path, filename, "处理腾讯云上传的key");
    let dateTime = new Date();
    let Y = dateTime.getFullYear(),
      M = dateTime.getMonth() + 1,
      D = dateTime.getDate(),
      H = dateTime.getHours(),
      Mi = dateTime.getMinutes(),
      S = dateTime.getSeconds(),
      Ms = dateTime.getMilliseconds();
    Y = Y.toString();
    M = M < 10 ? "0" + M : M;
    D = D < 10 ? "0" + D : D;
    H = H < 10 ? "0" + H : H;
    Mi = Mi < 10 ? "0" + Mi : Mi;
    S = S < 10 ? "0" + S : S;
    let KEY = path + Y + M + D + "_" + H + Mi + S + Ms + "_" + filename;
    return KEY;
  },
  handleUploadKey_tu: (keyInfo) => {
    //处理腾讯云上传的key
    console.log(3131);
    let { path, iFFilename, filename } = keyInfo;
    let dateTime = new Date();
    let Y = dateTime.getFullYear(),
      M = dateTime.getMonth() + 1,
      D = dateTime.getDate(),
      H = dateTime.getHours(),
      Mi = dateTime.getMinutes(),
      S = dateTime.getSeconds(),
      Ms = dateTime.getMilliseconds();
    Y = Y.toString();
    M = M < 10 ? "0" + M : M;
    D = D < 10 ? "0" + D : D;
    H = H < 10 ? "0" + H : H;
    Mi = Mi < 10 ? "0" + Mi : Mi;
    S = S < 10 ? "0" + S : S;
    Ms = Ms < 10 ? "00" + Ms : Ms < 100 ? "00" + Ms : Ms;

    let timeKey, UUID;
    timeKey = Y + M + D + "_" + H + Mi + S + Ms;
    UUID = "_" + Vthis.uuid(8).replace(/\-/g, "");
    let noFilenameKey =
      timeKey + UUID + "_" + Vthis.$config.hadleFileName(filename);
    let key = path + "/" + (iFFilename ? filename : noFilenameKey);

    return key;
  },
  obtainUrlCode: function () {
    let urlParams = location.search;
    let arr = urlParams.split("?");
    console.log(arr, "urlParams");
    if (arr.length <= 1) {
      return;
    }
    let paramsArr = arr[1].split("&");
    let paramsObj = {};
    for (let item of paramsArr) {
      paramsObj[item.split("=")[0]] = item.split("=")[1];
    }
    console.log(paramsObj, "paramsObj");
    return paramsObj.code;
  },
  funcUrlDel(name) {
    var loca = window.location;
    var baseUrl = loca.origin + loca.pathname + "?";
    var query = loca.search.substr(1);
    if (query.indexOf(name) > -1) {
      var obj = {};
      var arr = query.split("&");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].split("=");
        obj[arr[i][0]] = arr[i][1];
      }
      delete obj[name];
      var url =
        baseUrl +
        JSON.stringify(obj)
          .replace(/[\"\{\}]/g, "")
          .replace(/\:/g, "=")
          .replace(/\,/g, "&");
      return url;
    } else {
      return window.location.href;
    }
  },
  isKsz() {
    if (userAgent.includes("m2oSmartCity_useragent")) {
      return true;
    } else {
      return false;
    }
  },
  isSzd() {
    if (userAgent.includes("szd_SZGov")) {
      return true;
    } else {
      return false;
    }
  },
  isWeiXin() {
    let userAgent = window.navigator.userAgent.toUpperCase();
    if (userAgent.indexOf("MICROMESSENGER") === -1) {
      // console.log('非微信')
      return false;
    } else {
      return true;
    }
  },

  // 报名报错弹框提示

  applicationTipNotice: (info) => {
    let { msg, type } = info;
    console.log(111);
    Vthis.$notify({
      type: type ? type : "warning",
      title: "提示",
      message: msg,
      duration: 4500,
    });
  },

  nameInsertString(name) {
    if (name.length === 2) {
      return name[0] + "\xa0" + name[1];
    } else {
      return name;
    }
  },

  //秒级时间格式化
  formatSeconds(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    let hoursString = "";
    if (hours) {
      hoursString = hours.toString().padStart(2, "0") + ":";
    }
    return `${hoursString}${remainingMinutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  },
  padWithZero(number) {
    return number.toString().padStart(2, "0");
  },
  handleDesHtml(textarea) {
    if (textarea) {
      let newString = textarea.replace(/\n/g, "_@").replace(/\r/g, "_#");
      newString = newString.replace(/_#_@/g, "<br/>"); // IE7-8
      newString = newString.replace(/_@/g, "<br/>"); // IE9、FF、chrome
      newString = newString.replace(/\s/g, "&nbsp;");
      return newString;
    }
  },
  findLabelByValue(findList, value) {
    return findList.find((item) => item.value === value)?.label;
  },
};
