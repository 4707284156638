// 常量配置
import Vue from "vue";
const Vthis = new Vue();
export default {
  API: process.env.VUE_APP_API,
  phoneReg: /^1(3|4|5|6|7|8|9)\d{9}$/, // 手机号验证
  passwordReg: /[\dA-z]{6,24}$/, // 密码验证
  regAllNum: /^[0-9]+.?[0-9]*$/g, // 判断字符串是否为数字 ，判断正整数用
  regPlusNum: /^[1-9][0-9]*$/, // /^[1-9]+[0-9]*$/, //判断正整数用
  regPlusNum_: /^[0-9]+[0-9]*$/, // 判断正整数用 含0
  regUserName: /^[a-z0-9\u2E80-\u9FFF]{2,14}$/, // 用户名验证
  regVerifyCode: /\d{6}$/, //验证码
  TimerCode: 11, //字典值厅类别
  ContentTag: 13, //作品标签字典
  ActivityCode: 14, //huo dong
  getStorageInfo: (name) => {
    // 获取cookie
    let arr,
      reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if ((arr = document.cookie.match(reg))) {
      return unescape(arr[2]);
    } else {
      return null;
    }
    // const str = sessionStorage.getItem(name)
    // if (str) {
    //   return str
    // } else {
    //   return null
    // }
  },
  setStorageInfo: (c_name, value, expiredays) => {
    // 设置cookie,增加到vue实例方便全局调用
    // sessionStorage.setItem(c_name, value)
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie =
      c_name +
      "=" +
      escape(value) +
      ";path=/;secure:true;" +
      (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
  },
  delStorageInfo: (name) => {
    // 删除cookie
    // setCookie(name, "", -1);
    Vthis.$config.setStorageInfo(name, "", -1);
    // sessionStorage.removeItem(name)
  },
  delAllStorageInfo: () => {
    // sessionStorage.clear()
    const tk = document.cookie;
    // let reg = new RegExp("(^| )" + 'token' + "=([^;]*)(;|$)");
    const keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
      for (const item of keys) {
        // console.log(item,'keys');
        Vthis.$config.delStorageInfo(item);
      }
    }
  },

  analysisToken: (tk) => {
    // 解析token
    let token;
    if (tk) {
      token = tk;
    } else {
      token = Vthis.$config.getStorageInfo("token");
    }
    let obj = {
      userInfo: null,
      userRole: null,
    };
    // console.log(token);
    if (!token) {
      return obj;
    }
    // 解析token
    let userToken = token.substr(7).split(".")[1];
    userToken = JSON.parse(Base64.decode(userToken));
    const userInfo = JSON.parse(userToken.sub);
    const userRole = userToken.scopes;
    obj = Object.assign({}, userToken);
    obj.userInfo = userInfo;
    obj.userRole = userRole;
    // console.log(obj,'用户信息')
    return obj;
  },
  judgeIsLogin: () => {
    // 判断是否登录
    let token = Vthis.$config.getStorageInfo("token");
    if (token) {
      return true;
    } else {
      return false;
    }
  },

  readingRoomType: {
    booksWorks: "书籍",
    picWorks: "图片",
    songsWorks: "音频",
    videoWorks: "视频",
  },
};
