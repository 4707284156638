<template lang="html">
  <section
    id="app"
    :class="[
      currPlatForm,
      $route.meta.routeType,
      $route.meta.isHasTabBar ? 'HASTABBAR' : '',
      $route.meta.isHasNavBar ? 'HASNAVBAR' : '',
      $route.meta.isHasNavBarSearch ? 'HASNAVBAR' : '',
      $route.meta.isHasNavBar && $route.meta.isHasNavBarSearch
        ? 'app-has-navbar-search'
        : '',
    ]"
  >
    <!-- 头部导航栏 -->
    <Header :currPlatForm="currPlatForm" />
    <NavBar :currPlatForm="currPlatForm" />

    <section class="mainWrap">
      <router-view :currPlatForm="currPlatForm" />
    </section>
    <Footer v-if="!$route.meta.hideFooter" :currPlatForm="currPlatForm" />
    <Tabbar :currPlatForm="currPlatForm" />
  </section>
</template>
<script>
import API from "@/api/api";
import Header from "@/components/header";
import Footer from "@/components/footer";
import Tabbar from "@/components/tabbar.vue";
import NavBar from "@/components/phone/NavBar.vue";
export default {
  components: {
    Header,
    Footer,
    Tabbar,
    NavBar,
  },
  data() {
    return {
      currPlatForm: "",
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(now, old) {
        this.currPlatForm = this.$config.obtainCurrPlatform();
        this.handle_PC_PHONE_ROUTE(now, old);
        this.handleWxConfig();
      },
    },
  },
  beforeCreate() {},
  created() {
    this.currPlatForm = this.$config.obtainCurrPlatform();
    window.onresize = () => {
      this.currPlatForm = this.$config.obtainCurrPlatform();
      // console.log(this.currPlatForm, "window.onresize--APP.VUE2");
    };
  },
  mounted() {},
  methods: {
    handleWxConfig() {
      this.$nextTick((_) => {
        // this.$config.wxShare({title:'网链苏州 江南向wang',desc:'苏州市网络文明生态园',$this:this})
      });
    },

    handle_PC_PHONE_ROUTE(now, old) {
      // console.log(this.$route)
      let PLATFORM = this.currPlatForm;
      // console.log(PLATFORM,'PLATFORM')
      let { path, hash } = this.$route;
    },
  },
};
</script>
<style lang="less">
@import "config/css/root.css";
@font-face {
  font-family: HelloFont_WenYiHei;
  src: url("./config/font/HelloFont-WenYiHei.ttf");
}

@font-face {
  font-family: YouSheBiaoTiYuan;
  src: url("./config/font/YouSheBiaoTiYuan-2.otf");
}
@font-face {
  font-family: Fontquan-XinYiJiXiangSong;
  src: url("./config/font/Fontquan-XinYiJiXiangSong.ttf");
}
@font-face {
  font-family: ZiTiQuanXinYiGuanHeiTi;
  src: url("./config/font/ZiTiQuanXinYiGuanHeiTi4.0-2.ttf");
}

html,
body {
  //font-family: "Microsoft YaHei", "微软雅黑","AlibabaPuHuiTi","PingFang SC","Source Han Sans CN","思源黑体","Heiti SC","SimHei","黑体",sans-serif;
  // font-family: HarmonyOS_Sans_SC_Regular !important;
}
#app {
  position: relative;

  &.PC {
    width: 100%;
    //height: 100vh;
    min-height: 100vh;
    background-color: var(--pc-bgColor);
    .mainWrap {
      min-height: ~"calc(100vh - 70px - 195px)";
      position: relative;
    }
    &.adminRoute {
      height: 100%;
      //background-color: #F56C6C;
      .mainWrap {
        height: 100%;
      }
    }
  }
  &.PHONE {
    .pageWrap {
      // height: 100vh;
      min-height: 100vh;
      box-sizing: border-box;
      padding-bottom: ~"calc(var(--safe-area-bottom-constant))";
      padding-bottom: ~"calc(var(--safe-area-bottom-env))";
      //background-color: #FFFFFF;
      background-color: #fff;
      position: relative;
    }
    &.HASTABBAR {
      .pageWrap {
        //min-height: 100vh;
        //box-sizing: border-box;
        padding-bottom: ~"calc(130rem + var(--safe-area-bottom-constant))";
        padding-bottom: ~"calc(130rem + var(--safe-area-bottom-env))";
      }
    }
    &.HASNAVBAR {
      .pageWrap {
        //min-height: 100vh;
        //box-sizing: border-box;
        padding-top: ~"calc(130rem + var(--safe-area-top-constant))";
        padding-top: ~"calc(130rem + var(--safe-area-top-env))";
      }
    }
    &.app-has-navbar-search {
      .pageWrap {
        //min-height: 100vh;
        //box-sizing: border-box;
        padding-top: ~"calc(260rem + var(--safe-area-top-constant))";
        padding-top: ~"calc(260rem + var(--safe-area-top-env))";
      }
    }
  }
}
</style>
