import sendAjax from "@/api/http";
export const sss = 1;

//搜索名人
export const celebritySearch = (params) => {
  return sendAjax("post", `/celebrity/search`, params);
};
export const contentSearch = (params) => {
  return sendAjax("post", `/content/search`, params);
};
export const getcelebrityDetail = (id) => {
  return sendAjax("get", `/celebrity/detail?id=${id}`);
};
export const getDictList = (id) => {
  return sendAjax("get", `/dict/getDictList?typeId=${id}`);
};
export const getContentDetail = (id) => {
  return sendAjax("get", `/content/detail/${id}`);
};
export const getContentdynasty = () => {
  return sendAjax("get", `/celebrity/dynasty`);
};
export const getRelationshipGraph = (dynasty) => {
  return sendAjax("get", `/celebrity/relationship/graph?dynasty=${dynasty}`);
};
export const getHomeBanner = () => {
  return sendAjax("get", `/home/banner`);
};
export const getAreaCelebrity = () => {
  return sendAjax("get", `/home/area/celebrity`);
};
export const getAssociationalList = (KeyWord) => {
  return sendAjax("get", `/celebrity/associational/list?KeyWord=${KeyWord}`);
};
export const getPreviewUrl = (url) => {
  return sendAjax("get", `/content/preview?url=${url}`);
};
export const getPreviewUrlToken = (url) => {
  return sendAjax("get", `/content/imm/getToken?sourceURI=${url}`);
};
export const getHomeRecommend = () => {
  return sendAjax("get", `/home/celebrity/recommend`);
};
export const getSearch = (params) => {
  return sendAjax("post", `/celebrity/content-celebrity/search`, params);
};
export const getGeoInfo = (params) => {
  return sendAjax("post", `/home/area/geoInfo`, params);
};
export const getActivityList = (params) => {
  return sendAjax("post", `/home/activity/list`, params);
};
export const getActivityDetail = (id) => {
  return sendAjax("get", `/home/activity/detail/${id}`);
};
export const getHallCelebrity = (id) => {
  return sendAjax("get", `/hall/celebrity`);
};

export const getHallList = (params) => {
  return sendAjax("post", `/hall/list`, params);
};
