import Axios from 'axios'
import Vue from 'vue'
import $config from '@/config/js/config.js'
const that = new Vue()



let base = $config.API;


// base = 'https://api.wlsz.net.cn/api/'
// base = 'http://192.168.1.250:8066/


const instance = Axios.create({
  headers: {
    'Content-Type': 'application/json',
    'API_ERROR':location.search?location.href+`&rqe=${$config.obtainCurrPlatform()}`:location.href+`?rqe=${$config.obtainCurrPlatform()}`
  },
  baseURL: base
})

if($config.obtainCurrPlatform()==='PHONE'){

// 请求拦截器
  instance.interceptors.request.use(
      config => {
        let token = $config.getStorageInfo('token');
        if (token) {
          // config.headers.Authorization = token
          config.headers['nl-Authorization'] = token;
        }
        return config
      },
      err => {
        that.$toast('网络错误')

        return Promise.reject(err)
      })

// 响应拦截器
  instance.interceptors.response.use(
      response => {
        if (response.status === 200) {
          // console.log(response, '38')
        } else {
          // console.log(response.data.message)
          that.$toast(response.data.message)
        }
        return response
      },
      error => {
        if (error.response) {
          if (error.response.status === 403) {
            console.log('无权限')
            if($config.getStorageInfo('token')){
              that.$toast('无权限,请重新登录');
            }else{
              that.$toast('请退出后，重新进入');
            }
          }else {
            let msg = error.response.data.message || error.response.data.error || error.message;
            that.$toast(msg);
          }
        } else {
          // console.log(error.message)
          that.$toast(error.message);
        }
        return Promise.reject(error)
      })
}else{

// 请求拦截器
  instance.interceptors.request.use(
      config => {
        let token = $config.getStorageInfo('token');
        if (token) {
          // config.headers.Authorization = token
          config.headers['nl-Authorization'] = token;
        }
        return config
      },
      err => {
        that.$message.error('网络错误')
        return Promise.reject(err)
      })

// 响应拦截器
  instance.interceptors.response.use(
      response => {
        if (response.status === 200) {
          // console.log(response, '38')
        } else {
          // console.log(response.data.message)
          that.$message.error(response.data.message)

        }
        return response
      },
      error => {
        if (error.response) {
          if (error.response.status === 403) {
            console.log('无权限')

              if($config.getStorageInfo('token')){
                  that.$message.error('无权限,请重新登录');
              }else{
                  that.$message.error('请退出后，重新进入');
              }

          }else {
            let msg = error.response.data.message || error.response.data.error || error.message;
              that.$message.error(msg);
          }
        } else {
          console.log(error.message)
            that.$message.error(error.message)
        }
        return Promise.reject(error)
      })
}











const sendAjax = async (method, url, params) => {
  method = method.toLowerCase()
  let config = {
    method,
    url
  }
  try {
    if(method==='get'||method==='head'){
      config.params = params;
    }else if(method==='post'||method==='delete'||method==='put'||method==='patch'){
      config.data = params;
    }else{
      config.params = params;
    }
    return await instance.request(config)
  }catch (e) {
    return Promise.reject(e)
  }

  // else if(method==='options'){
  //
  //   }
}



// const sendAjax = async (method, url, par) => {
//   let data,params
//   method = method.toLowerCase()
//   if (!par) params = {}
//   if (method.indexOf('get') >= 0) {
//     data = {}
//     params = par
//   } else {
//     data = par
//   }
//   try {
//     const res = await instance.request({
//       url,
//       method,
//       params,
//       data
//     })
//     return res
//   } catch (error) {
//     // error.response.data.message
//     return Promise.reject(error)
//   }
// }

export default sendAjax
