var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currPlatForm === 'PHONE')?_c('fragment',[(_vm.$route.meta.isHasNavBar)?_c('section',{staticClass:"navBarBox"},[_c('van-nav-bar',{attrs:{"left-arrow":"","placeholder":false,"safe-area-inset-top":true,"fixed":"","border":false,"z-index":"1000"},on:{"click-left":_vm.onClickLeft}},[_c('div',{attrs:{"slot":"title","id":"navTitle"},slot:"title"},[_vm._v(_vm._s(_vm.$route.meta.title))])])],1):_vm._e(),(_vm.$route.meta.isHasNavBarSearch)?_c('section',{class:[
      'navBarBox',
      'searchNavBar',
      _vm.$route.meta.isHasNavBar && _vm.$route.meta.isHasNavBarSearch
        ? 'hasAll'
        : '',
    ]},[_c('van-nav-bar',{attrs:{"placeholder":false,"safe-area-inset-top":true,"fixed":"","border":false,"z-index":"1000"},on:{"click-left":_vm.onClickLeft}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"searchIpt"},[_c('van-search',{attrs:{"placeholder":"搜索感兴趣的信息哦~"},on:{"search":_vm.clickSearch},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)])])],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }