import BASIC from '@/config/js/basicConfig.js'
import CONSTANT from '@/config/js/constantConfig.js'
import METHOD from '@/config/js/methodConfig.js'
import UPLOAD from '@/config/js/upload.js'

import PRO_CI_AR from '@/config/js/pcaData.js'
// PRO_CI_AR_LIST
//PRO_CI_LIST
export default {
  ...BASIC,
  ...CONSTANT,
  ...METHOD,
  ...UPLOAD,
  ...PRO_CI_AR,
}
