<template>
  <fragment v-if="currPlatForm == 'PC'">
    <section id="app-footer" class="pageWrap footerWrapPC">
      <div class="footerWarp">
        <div class="footerbox">
          <div
            v-for="item in footerList"
            :key="item.name"
            class="footer_item"
            @click="goto(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="footer_logo" />
      <div class="footer_info">
        <span>地址：苏州人民路2075号</span>
        <span>备案号：苏ICP备12000510号</span>
      </div>
      <div class="weixin share" />
      <div class="weibo share" />
    </section>
  </fragment>
</template>

<script>
// import API from "@/api/api.js";
export default {
  name: "Footer",
  props: {
    currPlatForm: {
      type: String,
      default: "PC",
    },
  },
  data() {
    return {
      footerList: [
        {
          name: "名人风采",
          path: "/showPortraitMode",
        },
        {
          name: "遗迹地图",
          path: "/relics",
        },
        {
          name: "阅览室",
          path: "/readingRoom",
        },
        {
          name: "名人展厅",
          path: "/exhibition",
        },
        {
          name: "最新动态",
          path: "/activity",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    goto(item) {
      this.$router.push(item.path);
    },
  },
};
</script>

<style lang="less" scoped>
.footerWrapPC {
  width: 100%;
  height: 195px;
  background: #33485e url("../assets/img/footer.png");
  position: relative;
  .footerWarp {
    width: 100%;
    border-bottom: 1px solid #becece;
    .footerbox {
      width: 1200px;
      margin: 0 auto;
      height: 54px;
      border-left: 1px solid #becece;
      display: flex;
      .footer_item {
        cursor: pointer;
        flex: 1;
        text-align: center;
        font-size: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #d0e0e0;
        border-right: 1px solid #becece;
        height: 100%;
        line-height: 54px;
      }
    }
  }
  .footer_logo {
    width: 192px;
    height: 32px;
    background: url("../assets/img/footer_logo.png");
    background-size: 100% 100%;
    margin-top: 20px;
    margin-left: 120px;
  }
  .footer_info {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #d0e0e0;
    margin-top: 20px;
    margin-left: 120px;
    span {
      margin-right: 37px;
    }
  }
  .share {
    width: 90px;
    height: 90px;
    position: absolute;
  }
  .weibo {
    background: url("../assets/img/weibo.png");
    background-size: 100% 100%;
    top: 74px;
    right: 120px;
  }
  .weixin {
    background: url("../assets/img/weixin.png");
    background-size: 100% 100%;
    top: 74px;
    right: 240px;
  }
}
</style>
